<template>
  <section id="CreditOneClubMobile">
    <SideBarNavMobile subsectionTitle="Credit One Club" />
    <div class="mobile-overlay"></div>
    <Photos />
    <Layout />
    <div class="field-view">
      <div class="dividerOne"></div>
      <div class="dividerTwo"></div>
      <h1>
        Field View
        <div class="line"></div>
      </h1>
      <iframe
        src="https://salesdeck.allegiantstadium.com/vr/Credit-One-Club/tour.html"
      ></iframe>
    </div>
  </section>
</template>

<script>
import Photos from "@/components/sections/EventSpaces/CreditOneClub/Photos";
import Layout from "@/components/sections/EventSpaces/CreditOneClub/Layout";
import SideBarNavMobile from "@/components/Nav/SideBarNavMobile";

export default {
  name: "TwitchLoungeMobile",
  components: {
    Photos,
    Layout,
    SideBarNavMobile,
  },
};
</script>

<style lang="scss" scoped>
#CreditOneClubMobile {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-image: url("~@/assets/images/MobileBG/Credit-One-BG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin-top: -60px;
  .mobile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.83) 20%,
      rgba(0, 0, 0, 0.92) 82%
    );
  }

  .field-view {
    position: relative;
    width: 100%;
    h1 {
      font-family: "ArtegraSans-Bold";
      font-size: 26px;
      color: #ffffff;
      letter-spacing: 1.62px;
      line-height: 25px;
      width: fit-content;
      margin-bottom: 20px;
      text-transform: uppercase;
      @media (max-width: 993px) {
        margin-left: 100px;
      }
      @media (max-width: $md) {
        margin-left: 50px;
      }
      @media (max-width: $sm) {
        margin-left: 20px;
      }
      .line {
        height: 2px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
        margin-top: 20px;
      }
    }
    .dividerOne {
      width: 100%;
      height: 2px;
      background: #1a1a1a;
      margin-top: 40px;
    }
    .dividerTwo {
      width: 100%;
      height: 2px;
      background: #2c2c2c;
      margin-bottom: 40px;
    }
    iframe {
      width: 100%;
      height: calc((100vw / 16) * 9);
      border: 0;
    }
  }
}
</style>